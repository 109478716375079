<template>
    <div style="width:80mm; direction:rtl;">
        <b-sidebar backdrop lazy aria-labelledby="sidebar-no-header-title" no-header id="view_invoice" class="text-center;" width="100rem"  style="direction:rtl;" right shadow >
            
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#28467c;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>استعراض فاتورة</span>
        </div>
        <div @click="hide" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>اغلاق</span>
      </div>
    </div>        
            <div style="width:21cm;margin:auto" id="printmes3" v-if="isCanceled == false" >
                <div style="width:21cm;margin:auto" class="text-center btn-warning pa-5" v-if="invoiceData.status == -5">
                    <h6>هذه الفاتورة مرتجعة</h6>
                    تم الغاء هذه البطاقة بواسطة: {{ invoiceData._cancel_by }} - بتاريخ: {{invoiceData.cancel_date}} - وسبب الالغاء: {{invoiceData.cancel_reson}}
                </div>

                <v-simple-table>
                    <template v-slot:default>
                        <tbody>
                          <tr>
                            <th style="border:1px solid #ccc; text-align:center">نوع الفاتورة</th>
                            <td style=" text-align:center">{{ invoiceData._invtype }}</td>
                            <th style="border:1px solid #ccc; text-align:center">اجمالي المبلغ</th>
                            <td style=" text-align:center">{{ invoiceData.ftotal }}</td>
                            <th style="border:1px solid #ccc; text-align:center">المبلغ المدفوع</th>
                            <td style=" text-align:center">{{ invoiceData.paid }}</td>
                            <th style="border:1px solid #ccc; text-align:center">المتبقي</th>
                            <td style=" text-align:center">{{ invoiceData.remain }}</td>
                          </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <v-simple-table striped hover style="width:100%;">
                    <template v-slot:default>
                        <tbody>
                          <tr>
                            <th style="width:25%;border:1px solid #ccc; text-align:center">اسم العميل</th>
                            <td style="width:25%; text-align:center">{{ invoiceData.full_name }}</td>
                            <th style="width:25%;border:1px solid #ccc; text-align:center">رقم الجوال</th>
                            <td style="width:25%; text-align:center">{{ invoiceData.mobile }}</td>
                          </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                
                <v-simple-table striped hover style="width:100%;margin-top:5px;">
                    <template v-slot:default>
                        <tbody>
                          <tr>
                            <th style="width:25%;border:1px solid #ccc; text-align:center">رقم اللوحة</th>
                            <td style="width:25%; text-align:center">{{ invoiceData.plate_number }}</td>
                            <th style="width:25%;border:1px solid #ccc; text-align:center">نوع السيارة</th>
                            <td style="width:25%; text-align:center">{{ invoiceData.model }}</td>
                          </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-simple-table striped hover style="width:100%;margin-top:5px;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th style="width:10%; text-align:center">رمز الصنف</th>
                                <th style="width:25%; text-align:center">بيان الصنف</th>
                                <th style="width:10%; text-align:center">الكمية</th>
                                <th style="width:10%; text-align:center">السعر</th>
                                <th style="width:15%; text-align:center">الاجمالي بدون ضريبة</th>
                                <th style="width:15%; text-align:center">الضريبة 15%</th>
                                <th style="width:15%; text-align:center">الاجمالي مع الضريبة</th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(row,index) in invoiceData.rows" :key="index">
                            <td style="width:10%;text-align:center">{{ row.itemcode }}</td>
                            <td style="width:25%;text-align:center">{{ row.item_name }}</td>
                            <td style="width:10%; text-align:center">{{ row.qty }}</td>
                            <td style="width:10%; text-align:center">{{ row.item_price }}</td>
                            <td style="width:15%; text-align:center">{{ row.total }}</td>
                            <td style="width:15%; text-align:center">{{ row.vat }}</td>
                            <td style="width:15%; text-align:center">{{ row.ftotal }}</td>
                          </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colspan="6" style="text-align:left;">المجموع بدون ضريبة</th>
                                <th style="text-align:center;">{{ invoiceData.total }}</th>
                            </tr>
                            <tr>
                                <th colspan="5" style="text-align:left;">الضريبة 15%</th>
                                <th style="text-align:center;">{{ invoiceData.vat }}</th>
                            </tr>
                            <tr>
                                <th colspan="5" style="text-align:left;">الاجمالي نقدا</th>
                                <th style="text-align:center;">{{ invoiceData.ftotal }}</th>
                            </tr>
                        </tfoot>
                    </template>
                </v-simple-table>
                <div style="width:70%;margin:auto"  v-if="invoiceData.status == 1">
                    <v-text-field
                        label="سبب الالغاء"
                        v-model="cancel_reson" 
                        required
                        id="cancelreson"
                    >{{ invoiceData.cancel_reson }}</v-text-field>    
                </div>  
                <div style="width:70%;margin:auto;color:red;text-align:center" v-if="showalert">
                    يجب كتابة سبب ارجاع الفاتورة ويجب أن يكون اكثر من 10 حروف
                </div>      
            </div>
    </template>
            <template #footer="{hide}" class="shadow">
                <div class="m-5 text-center">
                    <b-button size="sm" @click="HideIT() , hide" class="myBTN myBTN2">قائمة الفواتير</b-button>
                    <b-button size="sm" v-if="invoiceData.status == 1" @click="cancelInvocie()" class="myBTN myBTN1">ارجاع الفاتورة</b-button>
                    <b-button size="sm" @click="printInvo()" class="myBTN myBTN2">طباعة</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: ['invoice'],
    data() {
        return {
            isCanceled: false,
            cardid: 0,
            invoiceData: '',
            showalert: false,
            cancel_reson: '',
            doitrelay: false
        }
        
    },
    created() {
        this.invoiceData = this.invoice;
        this.getInvoice();
    },
    methods: {
        printInvo(){
            window.open('../api/print.php?invid='+this.invoice.id,'_blank')
            this.HideIT();
            this.hide;
        },
        HideIT(){
            this.$parent.viewInvoice = false;
        },
        cancelInvocie()
        {
            if(this.cancel_reson == '' || this.cancel_reson.length < 10){
                document.getElementById('cancelreson').focus();
                this.showalert = true;
                return false;
            }
            this.$snotify.error('هل أنت متأكد بانك تريد الغاء الفاتورة؟', 'تحذير', {
                timeout: 6000,
                showProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                buttons: [
                    {
                        text: 'متأكد', 
                        action: (toast) => {
                            this.doCancel()
                            this.$snotify.remove(toast.id); 
                        },
                        bold: false
                    },
                    {
                        text: 'اغلاق', 
                        action: (toast) => {
                            this.$snotify.remove(toast.id); 
                        } 
                    },
                ]
            })
            if(!this.doitrelay){
                return false;
            }
        },
        doCancel(){
            const post = new FormData();
            post.append('type', 'cancelInvo');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',this.invoice.id)
            post.append('data[cancel_reson]',this.cancel_reson)
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                console.log(response.data);
                const res = response.data;
                this.HideIT()
                this.hide
            })
        },
        getInvoice(){
            const post = new FormData();
            post.append('type', 'getInvoice');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[cardid]',this.invoice.id)
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.invoiceData = res.results.data.results[0];
                console.log("hooolll",this.invoiceData);
            })
        }
    }
}
</script>
<style>
#printme *{
    direction:rtl;
    font-size:12px !important;
}
#printme{
    font-size:12px !important;
}
.myBTN{
    width:100px;
    margin:0 3px;
}
.myBTN1,.myBTN1:hover{
    background:rgb(255, 0, 0);
}
.myBTN2,.myBTN2:hover{
    background:rgb(0, 110, 255);
}
.myBTN3,.myBTN3:hover{
    background:rgb(1, 153, 47);
}
</style>